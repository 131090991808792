<template>
	<AtomsModal @close="closePopup" class="nomoney-modal">
		<img class="nomoney-img" src="/assets/images/png/nomoney/vibes.png" alt="">
		<h2 class="nomoney-title">{{ $t('modal_no_money.title') }}</h2>
		<p class="nomoney-description">{{ $t('modal_no_money.text') }}</p>
		<NuxtLinkLocale @click="closePopup" to="/payments" class="btn btn-secondary">{{ $t('interface.top_up_balance') }}</NuxtLinkLocale>
	</AtomsModal>
</template>

<script setup lang="ts">
const { closePopup } = useNomoneyModal()
</script>
<style lang="scss" scoped>
.nomoney-modal {
	:deep(.modal-content) {
		max-width: 429px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 24px;
		box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
		padding: 36px 45px 32px;
	}

	.btn {
		margin-top: 21px;
		width: fit-content;
		font-weight: 700;
		font-size: 16px;
		line-height: 80%;
		letter-spacing: -0.03em;
		color: #fff;
		padding: 12px 40px 10px;
	}
}

.nomoney-img {
	width: 128px;
	height: 128px;
}

.nomoney-title {
	font-weight: 700;
	font-size: 28px;
	line-height: 110%;
	letter-spacing: -0.03em;
	color: #1d254a;
	margin-top: 24px;
}

.nomoney-description {
	font-weight: 600;
	font-size: 18px;
	line-height: 130%;
	letter-spacing: -0.03em;
	color: #1d254a;
	margin-top: 12px
}

@media (max-width:1023px) {
	.nomoney-modal {
		:deep(.modal-content) {
			padding: 18px 16px 16px;
			max-width: 344px
		}

		.btn {
			margin-top: 18px;
			padding: 11px 40px 9px;
		}
	}

	.nomoney-img {
		width: 97px;
		height: 97px;
	}

	.nomoney-title {
		font-size: 24px;
		margin-top: 18px;
	}

}
</style>
